kbd {
    padding: .1em .6em;
    border: 1px solid #ccc;
    font-size: 11px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #f7f7f7;
    color: #333;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #FFFFFF inset;
    border-radius: 3px;
    display: inline-block;
    margin: 0 .1em;
    text-shadow: 0 1px 0 #fff;
    line-height: 1.4;
    white-space: nowrap;
    text-transform: capitalize;
    min-width: 25px;
    text-align: center;
}

.table-kbd td,
.table-kbd th {
    border: 0px !important;
}
