#btn(@class, @bg) {
    .btn-@{class} {
        background-size: 200%;
        background-position: 50%;

        &:hover,
        &:active:hover,
        &:focus {
            background-color: darken(@bg, 6%);
        }

        &:active {
            background-color: darken(@bg, 6%);
            #gradient > .radial(darken(@bg, 6%) 10%, @bg 11%);
            background-size: 1000%;
            .box-shadow(2px 2px 2px rgba(0, 0, 0, .3));
        }

    }

}

#btn(default, @btn-default-bg);
#btn(primary, @btn-primary-bg);
#btn(success, @btn-success-bg);
#btn(info, @btn-info-bg);
#btn(warning, @btn-warning-bg);
#btn(danger, @btn-danger-bg);

.btn {
    text-transform: uppercase;
    border-right: none;
    border-bottom: none;
    .box-shadow(1px 1px 2px rgba(0, 0, 0, .3));
    .transition(all 0.2s);

    &-link {
        .box-shadow(none);

        &:hover,
        &:focus {
            color: @brand-primary;
            text-decoration: none;
        }

    }

    &-default.disabled {
        border: 1px solid @btn-default-border;
    }

}

.btn-group {
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
        margin-left: 0;
    }

    &-vertical {
        > .btn + .btn,
        > .btn + .btn-group,
        > .btn-group + .btn,
        > .btn-group + .btn-group {
            margin-top: 0;
        }

    }

}
