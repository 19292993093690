.loader:not(.loaded) * {
    position: absolute !important;
    visibility: hidden !important;
}

.loader:not(.loaded) {
    position: relative;
    min-height: 20em;
    overflow: hidden;

    > .spinner {
        display: block;
    }

}

.loader > .spinner {
    position: absolute !important;
    visibility: visible !important;
    left: 50%;
    top: 50%;
    margin-top: -2.5em;
    display: none;
    font-size: 1em;
    text-indent: -9999em;
    border-style: solid;
    border-top-color: fade(@brand-primary, 20%);
    border-right-color: fade(@brand-primary, 20%);
    border-bottom-color: fade(@brand-primary, 20%);
    border-left-color: fade(@brand-primary, 100%);
    border-width: 0.8em;
    transform: translateZ(0);
    transform: translate(-50%, -50%);
    animation: spinner 1.1s infinite linear;
}

.loader > .spinner,
.loader > .spinner:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
}

@-webkit-keyframes spinner {
    .spinner-frames;
}

@keyframes spinner {
    .spinner-frames;
}

.spinner-frames() {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}

.loader.loader-sm > .spinner {
    left: 16px;
    font-size: 0.5em;
}
