.alert-fixed-bottom {
    position: fixed;
    bottom: @padding-base-vertical;
    display: table;
    right: 0;
    margin: 0;
    z-index: 10001;

    .alert {
        min-width: 400px;
    }

}
