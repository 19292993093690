.nav-tabs {
    > li > a,
    > li > a:focus {
        margin-right: 0;
        background-color: transparent;
        border: none;
        color: @navbar-default-link-color;
        .box-shadow(inset 0 -1px 0 #ddd);
        .transition(all 0.2s);

        &:hover {
            background-color: transparent;
            .box-shadow(inset 0 -2px 0 @brand-primary);
            color: @brand-primary;
        }

    }

    & > li.active > a,
    & > li.active > a:focus {
        border: none;
        .box-shadow(inset 0 -2px 0 @brand-primary);
        color: @brand-primary;

        &:hover {
            border: none;
            color: @brand-primary;
        }

    }

    & > li.disabled > a {
        .box-shadow(inset 0 -1px 0 #ddd);
    }

    &.nav-justified {
        & > li > a,
        & > li > a:hover,
        & > li > a:focus,
        & > .active > a,
        & > .active > a:hover,
        & > .active > a:focus {
            border: none;
        }

    }

    .dropdown-menu {
        margin-top: 0;
    }

}
