.table-responsive-force {
    overflow-x: auto;
    min-height: 0.01%;
    width: 100%;
    margin-bottom: (@line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid @table-border-color;

    > .table {
        margin-bottom: 0;

        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    white-space: nowrap;
                }

            }

        }

    }

    > .table-bordered {
        border: 0;

        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th:first-child,
                > td:first-child {
                    border-left: 0;
                }

                > th:last-child,
                > td:last-child {
                    border-right: 0;
                }

            }

        }

        > tbody,
        > tfoot {
            > tr:last-child {
                > th,
                > td {
                    border-bottom: 0;
                }

            }

        }

    }

    td:first-child {
        height: inherit;
        border-right: 0;
        border-bottom: 0;
        border-top: 1px solid #ddd;
        display: flex;

        > *:not(:first-child) {
            margin-left: 5px;
        }
    }

    tr:first-child td:first-child{
        border-top: 0;
    }

    .btn-group.btn-group-xs {
        position: static;
        display: flex;

        .dropdown-menu {
            top: inherit;
            margin-top: 23px;
            left: inherit;
            order: -1;
        }
    }

}

