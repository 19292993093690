@import (inline) "node_modules/sweetalert/dist/sweetalert.css";
@import "node_modules/bootstrap/less/bootstrap";
@import (inline) "node_modules/font-larisome/css/font-larisome.min.css";
@import (inline) "node_modules/bpg-extrasquare-mtavruli/css/bpg-extrasquare-mtavruli.css";
@import "node_modules/font-awesome/less/font-awesome";

@import "components/**/**";
@import "helpers/**/**";

@grid-float-breakpoint: @screen-md;
@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
@border-radius-base: 0px;
@border-radius-large: 0px;
@border-radius-small: 0px;

.btn {
    text-transform: lowercase !important;
}

body {
    padding-top: @navbar-height + @navbar-padding-vertical;
    overflow-y: scroll;
    margin-bottom: 50vh;
}

.indent {
    margin-left: 30px;
}

body,
label,
.checkbox label {
    font-weight: 300;
}

a,
.pointer {
    cursor: pointer;
}

a:hover {
    text-decoration: none;
}

.students {
    transition: all .1s ease;

    .result {
        transition: all .3s ease;
        max-height: 500px;
        overflow: hidden;

        &.vertical-collapsed {
            max-height: 0px;
        }

    }

}

.student {
    transition: width .2 ease;
}

.expandable {
    a {
        color: @gray-dark;

        i {
            transition: all .2s ease;
        }

        &:hover {
            color: @gray;
        }

    }

}

.caps {
    font-family: "BPG ExtraSquare Mtavruli";
    //text-transform: uppercase;
}

.btn {
    .caps;
}

.btn:focus {
    outline: none;
}

.list-group-title {
    text-decoration: none;
    color: @list-group-link-hover-color;
    background-color: @list-group-hover-bg;
}

.has-error input[type=radio],
.has-error input[type=radio]:focus {
    box-shadow: none;
}

th.checkbox-td {
    border-top: 0 !important;
}

.checkbox-td {
    width: 20px;
    text-align: center;
    vertical-align: middle !important;
    padding: 0 !important;

    input[type="checkbox"]:after,
    .checkbox input[type="checkbox"]:after,
    .checkbox-inline input[type="checkbox"]:after {
        margin-right: 0;
    }

}

.nav-tabs {
    margin-bottom: 20px;

    .active {
        font-weight: bold;
    }

}

.btn-badge {
    margin-top: -3px;
    margin-right: -8px;
}

td,
th {
    &.min {
        width: 1px;
    }

}

.drag-handle {
    cursor: -moz-grab;
    cursor: -webkit-grab;
    cursor: grab;
    padding: 10px 15px;
    margin: -10px -5px -10px -15px;
}

.sortable-ghost {
    opacity: 0.3;
}


.btn-group .dropdown-toggle.btn-block ~ .dropdown-menu {
    width: 100%;
}

.btn-group .dropdown-toggle.btn-default ~ .dropdown-menu {
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
        color: #fff;
        background-color: #008cba;
    }

}

.caret.pull-right {
    margin-top: 9px;
}

.btn.btn-block.dropdown-toggle.text-left {
    text-align: left;
}

.btn-group .dropdown-toggle .label,
.dropdown-menu .label {
    display: inline-block;
    padding: .6em !important;
    margin-top: -.3em;
    margin-bottom: -.3em;
    margin-left: -.3em;
}

.bbn {
    border-bottom: 0 !important;
}

.mbn {
    margin-bottom: 0 !important;
}

.mtn {
    margin-top: 0 !important;
}

.page-header {
    position: relative;

    small .label {
        top: -0.4em;
        display: inline-block;
        position: relative;
    }

    .progress {
        margin: 0;
        background: none;
        border: none;
        border-radius: 0;
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 1px;

        .progress-bar {
            height: inherit;
        }

    }

}


.disabled {
    opacity: .5;
}

.nowrap {
    white-space: nowrap;
}

.panel-zero {
    margin-bottom: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
}

.input-group-addon {
    width: 1px;
}

.input-group {
    width: 100%;
}

.input-xs {
    height: 22px;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}

.input-group-xs>.form-control,
.input-group-xs>.input-group-addon,
.input-group-xs>.input-group-btn>.btn {
    height: 22px;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}

.table-tabs {
    .nav-tabs {
        margin-bottom: -1px;
    }

    table.table:last-of-type {
        margin-bottom: 0;
    }

}

.disabled {
    pointer-events: none;
}

small .radio-inline {
    label {
        margin: 0;
    }

    input[type=radio] {
        margin-top: 1px;
    }

}

.dimmed {
    opacity: .5;
}

.sweet-alert,
.sweet-alert button {
    border-radius: 0;
}

.corner-action {
    width: 36px;
    height: 36px;
    text-align: center;
    padding: 8px;
    margin: -8px -16px;
}

.notification-icon {
    position: relative;
}

.notification-badge {
    background: #EA6966;
    position: absolute;
    top: 5px;
    left: 20px;
    padding: 3px 5px;
    border-radius: 5px;
    font-family: monospace;
    z-index: 10;
}

.badge.label-danger {
    background-color: #d9534f;
}

a.list-group-item-info.active,
button.list-group-item-info.active,
a.list-group-item-info.active:hover,
button.list-group-item-info.active:hover,
a.list-group-item-info.active:focus,
button.list-group-item-info.active:focus {
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3;
}

.panel-footer.panel-footer-btn-group {
    height: 54px;
}

.panel-footer.panel-footer-btn-group .loader-sm {
    min-height: 54px;
}

.panel-footer.panel-footer-btn-group .loader-sm.loading {
    margin: -10px -15px;
}

.activities-list-group {
    min-height: 20em;
}

[contenteditable] {
    outline: none;
}

textarea {
    resize: vertical;
}


.shift .non-selectable {
    user-select: none;
}

body.print {
    padding-top: 0;
    margin-bottom: 0;
}

body:not(.print) .print-only {
    display: none;
}


.obligation-distribution-input-group {
    display: flex;

    .obligation-distribution-input {
        width: 50%;
    }
}
