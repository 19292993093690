.tree-view {
    .list-group-item[data-toggle="collapse"]:not(.collapsed),
    .list-group-item[data-toggle="collapse"]:not(.collapsed):hover,
    .list-group-item[data-toggle="collapse"]:not(.collapsed):focus {
        z-index: 2;
        .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
    }

    > .list-group {
        .list-group-item:first-child {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        .list-group-item {
            border-width: 1px 0 0 0;
        }

    }

    .list-group {
        margin-bottom: 0;

        .list-group {
            margin-bottom: -11px;
            background-color: #fff;
            margin-top: 10px;
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 15px;
            padding-right: 15px;
        }

    }

    .list-group-item {
        border-radius: 0;
    }

    li.list-group-item > .list-group > a.list-group-item {
        margin: 0 -15px;
    }

    li.list-group-item li.list-group-item > a.list-group-item,
    li.list-group-item li.list-group-item {
        margin: 0 -15px;
        border-bottom: 0;
        padding-left: 30px;

        .list-group-item {
            margin: 0 -15px 0 -30px;
            padding-left: 45px;
        }

    }

    li.list-group-item li.list-group-item > a.list-group-item {
        margin: -10px -15px -10px -30px;
        padding-left: 30px;
    }

    li.list-group-item > a.list-group-item {
        margin: -10px -15px;
        border: 0px !important;
    }

    li.list-group-item a.list-group-item:last-child,
    li.list-group-item li.list-group-item a.list-group-item:last-child {
        margin-bottom: -10px;
    }

}
